<template>


  <div class="amendments-container">
      <h1 style="margin-left: 20px;">{{$t("legal-notice")}}</h1>
      <div class="support-con">
          <div class="con1 w-50">
              <a href="https://www.bos-id.eu/portal/impressum" target="_blank" class="text-black" @click="active='imprint'">
                <h3 :class="{active: active=='imprint'}">{{$t("imprint")}}</h3></a>
                <!-- <h3 class="active">{{$t("imprint")}}</h3> -->

                <a href="https://www.bos-id.eu/portal/nutzungsbedingungen" target="_blank" class="text-black" @click="active='terms'">
                    <h3 :class="{active: active=='terms'}">{{$t("Nutzungsbedingungen")}}</h3></a>

                <a href="https://www.bos-id.eu/portal/datenschutzerklaerung" target="_blank" class="text-black" @click="active='dpp'">
                    <h3 :class="{active: active=='dpp'}">{{$t("data-protection-policy")}}</h3></a>
          </div>
          <div class="con2">
              <!-- <p>Link to: </p>
              <span>https://www.bosid.eu/portal/erste_schritte</span>
              <span>https://www.bosid.eu/portal/erste_schritte</span>
              <span>https://www.bosid.eu/portal/erste_schritte</span> -->
          </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Popup from '../../components/dashboard/PopupExpiryDate.vue'
export default Vue.extend({
  name: 'ExpiredIDCards',
  components:{
      Popup
  },
  methods:{
      popUp(user){
          this.prop_user=user;
          const popup=this.$refs.popup ;
          popup.open();
      }
  },
  data(){
      return{
          active:'imprint',
          expiry_date :"",
          search:'',
          prop_user:[],
         
      }
  }
});
</script>
<style>

</style>